<template>
  <div class="about">
    <h1>This is an plusminus about page</h1>
  </div>
</template>

<script>
export default {
  mounted() {
    var baseUrl = window.location.origin;
  },
};
</script>
